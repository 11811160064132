@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    /* accents */
    --color-accent-100: 255 33% 98%;
    --color-accent-500: 257 53% 40%;
    --color-accent-800: 204 55% 19%;
    --color-accent-900: 204 56% 12%;

    /* grays */
    --color-gray-100: 0 0% 85%;
    --color-gray-200: 0 0% 70%;
    --color-gray-300: 0 0% 60%;
    --color-gray-450: 0 0% 13.3%; /* is this legal? */
    --color-gray-500: 0 0% 13%;

    /* highlight */
    --color-highlight-200: 40 38% 97%;
    --color-highlight-300: 37 20% 92%;

    /* other */
    --color-pviolet-500: 255 80% 58%;
  }

  body {
    font-size: 16px;
    @apply antialiased;
  }

  main {
    @apply mt-[65px] md:mt-[122px];
  }
}
