@font-face {
  font-family: Formular;
  src: local(Formular-Light), url("/fonts/Formular/Light.woff2") format("woff2"),
    url("/fonts/Formular/Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Formular;
  src: local(Formular), url("/fonts/Formular/Normal.woff2") format("woff2"),
    url("/fonts/Formular/Normal.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Formular;
  src: local(Formular-Medium),
    url("/fonts/Formular/Medium.woff2") format("woff2"),
    url("/fonts/Formular/Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}

@font-face {
  font-family: Formular;
  src: local(Formular-Bold), url("/fonts/Formular/Bold.woff2") format("woff2"),
    url("/fonts/Formular/Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  font-display: swap;
}
